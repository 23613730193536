module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-136117620-1","exclude":["/stage/**"],"head":true,"anonymize":false,"respectDNT":false,"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.solomonmotorgroup.com.au"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Solomon Motor Group","short_name":"Solomon Motor Group","start_url":"/","background_color":"#FFFFFF","theme_color":"#D62E2E","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e1baa87ea65807c23ec0b8d5ccea9c15"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
