// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-history-js": () => import("./../../../src/pages/our-history.js" /* webpackChunkName: "component---src-pages-our-history-js" */),
  "component---src-pages-our-staff-js": () => import("./../../../src/pages/our-staff.js" /* webpackChunkName: "component---src-pages-our-staff-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-used-vehicles-index-js": () => import("./../../../src/pages/used-vehicles/index.js" /* webpackChunkName: "component---src-pages-used-vehicles-index-js" */),
  "component---src-templates-mid-city-brochure-js": () => import("./../../../src/templates/MidCityBrochure.js" /* webpackChunkName: "component---src-templates-mid-city-brochure-js" */),
  "component---src-templates-mid-city-stock-js": () => import("./../../../src/templates/MidCityStock.js" /* webpackChunkName: "component---src-templates-mid-city-stock-js" */),
  "component---src-templates-mid-city-vehicle-js": () => import("./../../../src/templates/MidCityVehicle.js" /* webpackChunkName: "component---src-templates-mid-city-vehicle-js" */),
  "component---src-templates-south-nowra-brochure-js": () => import("./../../../src/templates/SouthNowraBrochure.js" /* webpackChunkName: "component---src-templates-south-nowra-brochure-js" */),
  "component---src-templates-south-nowra-stock-js": () => import("./../../../src/templates/SouthNowraStock.js" /* webpackChunkName: "component---src-templates-south-nowra-stock-js" */),
  "component---src-templates-south-nowra-vehicle-js": () => import("./../../../src/templates/SouthNowraVehicle.js" /* webpackChunkName: "component---src-templates-south-nowra-vehicle-js" */)
}

